<template>
  <div class="team">
    <v-toolbar flat>
      <h2>All Events</h2>
      <v-spacer></v-spacer>
      <router-link :to="{ name: 'Events' }">
        <v-btn fab text small color="grey darken-2">
          <v-icon large>
            mdi-calendar
          </v-icon>
        </v-btn>
      </router-link>
    </v-toolbar>
    <Layout
      @filter="filter"
      :filterCriteriaProps="filterCriteria"
      :hasFilter="false"
    >
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-auto bg-btn" dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item style="cursor: pointer">
              <v-list-item-title v-ripple @click="onButtonPress()"
                >ADD</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <div class="mt-5">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="deep-purple accent-4"
          class="mb-1"
        ></v-progress-linear>
        <v-simple-table class="event-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Venue</th>
                <th>Date</th>
                <th>Time</th>
                <th>Participant</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr v-for="e in events" :key="e.id">
                <td class="pl-5">{{ e.event_type.name }}</td>
                <td class="pl-5">{{ e.name }}</td>
                <td class="pl-5">{{ e.venue }}</td>
                <td class="pl-5">{{ e.date_formatted }}</td>
                <td class="pl-5">
                  {{ util.changeTimeFormat(e.date + " " + e.time) }}
                </td>
                <td class="pl-5">
                  <v-chip-group column>
                    <v-chip v-for="p in e.event_participants" :key="p.id">
                      {{ p.customer ? p.customer.full_name : "" }}
                    </v-chip>
                  </v-chip-group>
                </td>
                <td class="pl-5">{{ e.note }}</td>
                <td class="pl-5">
                  <v-icon class="icon-cursor" @click="editEvent(e.id)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon class="icon-cursor" @click="deleteEvent(e.id)"
                    >mdi-delete</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </Layout>

    <v-dialog v-model="dialog" width="1200" :key="key">
      <v-card>
        <v-card-title class="title text-h5">
          <span v-if="eventId === 0">
            ADD EVENT
          </span>
          <span v-else>
            EDIT EVENT
          </span>
        </v-card-title>
        <v-card-text class="py-5">
          <addEvent :id="eventId" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteModal" max-width="450px">
      <deleteDialog
        resource="event"
        :id="eventId"
        @cancel="cancel"
        @delete-success="deleteSuccess"
      />
    </v-dialog>
  </div>
</template>
<script>
import util from "@/util/util";
import Layout from "@/components/Contact/PersonalContactsInfos/Layout.vue";
import deleteDialog from "@/components/shared/delete-modal";
import addEvent from "./create.vue";
export default {
  components: { Layout, addEvent, deleteDialog },
  data() {
    return {
      util,
      dialog: false,
      showDeleteModal: false,
      events: [],
      filterCriteria: { length: 1, page: 1, q: "" },
      eventId: 0,
      key: 0,
      loading: false,
    };
  },
  mounted() {
    this.fetchEvents();
    util.event.$on("clearAddEmailParticipant", () => {
      this.fetchEvents();
      this.key++;
      this.eventId = 0;
      this.dialog = false;
    });
  },
  methods: {
    onButtonPress() {
      this.dialog = true;
    },
    fetchEvents() {
      this.loading = true;
      util
        .http({
          url: "/event",
          method: "get",
          params: this.filterCriteria,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            this.events = res.data.data.data;
            this.filterCriteria.length = res.data.data.last_page;
          }
        });
    },
    filter(n) {
      this.filterCriteria = n;
    },
    editEvent(id) {
      this.eventId = id;
      this.key++;
      this.dialog = true;
    },
    deleteEvent(id) {
      this.eventId = id;
      this.key++;
      this.showDeleteModal = true;
    },
    cancel() {
      this.showDeleteModal = false;
    },
    deleteSuccess() {
      this.showDeleteModal = false;
      this.eventId = 0;
      util.notify(1, "Event deleted");
      this.fetchEvents();
    },
  },
  watch: {
    filterCriteria: {
      handler() {
        this.fetchEvents();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.bg-btn {
  background: #1d2b58 !important;
  color: white !important;
}
.event-table th {
  font-weight: 700 !important;
  font-size: 1.3rem !important;
  color: #1d2b58 !important;
  text-align: left;
}
.table-body td {
  border-bottom: 0.2rem solid #ccc !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
}

.table-body tr td:nth-child(even),
thead th:nth-child(even) {
  background-color: #eee;
}
.table-body tr:hover {
  background: unset !important;
}
.table-body .v-chip {
  background: #ccc !important;
}

td .icon-cursor {
  cursor: pointer;
}
</style>
